import React from 'react'
import Footer from './layout/Footer'
import Header from './layout/Header'

const Services = () => {
    return (
        <div>
             <Header/>
                <section className="page-header">
                    <h3 className="text-uppercase text-center">Services</h3>
                </section>

                <section className="service1">
                    <div className="container">
                        <div className="row">
                            
                            <div className="col-lg-4 col-12">
                                <h4 className="text-uppercase">MEP & Building Services</h4>
                                <ul className="pt-2">
                                    <li>HVAC & Mechanical</li>
                                    <li>Electrical</li>
                                    <li>Plumbing</li>
                                    <li>Fire Detection System</li>
                                    <li>Fire Fighting</li>
                                    <li>Low Voltage System/Security System</li>
                                    <li>Integrated Building Management System (IBMS)</li>
                                    <li>BIM Modelling</li>
                                </ul>
                            </div>  

                            <div className="col-lg-4 col-12">
                                <h4 className="text-uppercase">Project Management</h4>
                                <ul className="pt-2">
                                    <li>Occasional site supervision and management for MEP services</li>
                                </ul>
                            </div>  

                            <div className="col-lg-4 col-12">
                                <h4 className="text-uppercase">Infrastructure Development MEP Services</h4>
                                <ul className="pt-2">
                                    <li>High Rise Building</li>
                                    <li>Master Planning</li>
                                    <li>Infrastructure Planning</li>
                                    <li>Infrastructure Expansion And Modernisation</li>
                                    <li>Hotels & Resorts</li>
                                    <li>Commercial buildings.</li>
                                    <li>Bungalows.</li>
                                    <li>Industrial</li>
                                    <li>Hospitals.</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="workflow bg-light">
                <h3 className="underline1 text-uppercase text-center">&nbsp; Our Work Flow &nbsp;</h3>
                <div className="container mt-5">
                    <div className="row">
                        <img src="./images/workflow.png" className="img-fluid" />
                    </div>
                </div>
            </section>


            <section className="mt-5 mb-5">
                <div className="container">
                <h4>DESIGN APPROACH</h4><hr/>
                    <p>WE APPROACH EVERY PROJECT AS UNIQUE PROJECT AND PROVIDE COST EFFECTIVE, FUNCTIONAL, SUSTAINABLE BY DESIGN, AND CODE COMPLIANT SOLUTION SUITABLE FOR THAT PROJECT IN LINE WITH CLIENTS SPECIFIC INPUT PARAMETERS.</p>
                    <p>WE ADD VALUES  IN ALL PHASES OF THE PROJECT </p>
                    <p>WE DELIVER  OUR DELIVERABLES IN LINE WITH THE REQUIREMENT OF THE PROJECT.</p>
                

                <h4 className="mt-5">GENERAL METHODOLOGY TO A PROJECT </h4><hr/>
                    <p>CONCEPT DESIGN</p>
                    <p>SCHEMATIC DESIGN</p>
                    <p>DESIGN DEVELOPMENT</p>
                    <p>TENDER DOCUMENTATION & AWARD OF CONTRACTS</p>
                    <p>CONSTRUCTION  CO-ORDINATION</p>
                    <p>COMMISSIONING, TESTING & HANDOVER PROCEDURE</p>

                    
                <h4 className="mt-5">CONCEPT DESIGN</h4><hr/>
                    <p>SETTING DESIGN CRITERIA & OBJECTIVES</p>
                    <p>HIGHLIGHTING SITE CONSTRAINTS AND SERVICES REQUIREMENTS.</p>
                    <p>ASSESSMENT OF PRELIMARY DEMAND AND CONSUMPTION –COOLING LOAD, ELECTRICITY, DOMESTIC, FLUSH & FIRE WATER REQUIREMENTS & SIZING OF TANKS.  PROVIDING ALTERNATES AS APPLICABLE.</p>
                    <p>BUILDING ENVELOPE ISSUES AND SHADING PARAMETERS</p>
                    <p>OUTLINE SLD OF DISTRIBUITON OF MAJOR BUILDING SERVICES</p>
                    <p>STATUTORY CODE REQUIREMENTS ANALYSIS W.R.T THE GIVEN APPLICATION AND PLANS (IN TERMS OF BOTH LOCAL BYE-LAWS AND INTERNATIONAL CODES)</p>
                    <p>PLANT & EQUIPMENTS SIZES AND LOCATIONS AND ITS SPACE ALLOCATION.</p>
                    <p>SUBMISSION OF DESIGN BASIS REPORT WITH MAJOR PRELIMINARY SINGLE LINE DIAGRAMS AND LAYOUTS WITH REQUIREMENTS OF CUTOUTS, SHAFTS, SERVICES SPACES  ETC.,</p>
                    <p>ON PRODUCTION OF BASIC DBR AND IT SCHEMTICS, IT IS EXPECTED THAT CLIENT AND ALL CONCERNED AGENCIES GIVES THE APPROVAL TO ''PC''AND THEN ''PC'' WILL PROCEED FURTHER TO THE NEXT STAGE OF SCHEMATIC DESIGN.</p>
                    <p>THE SAME DBR AND ITS SCHEMATICS CAN BE WELL USED FOR SUBMISSION TO AUTHORITIES LIKE MOEF, HRC, GREEN BUILDING IF AND AS APPLICABLE ETC.,</p>


                <h4 className="mt-5">SCHEMATIC DESIGN STAGE</h4><hr/>
                    <p> THIS IS EXTENSION OF THE CONCEPT APPROVED DBR AND ITS SCHEMATICS.</p>
                    <p> PREPRATION OF SINGLE LINE DIAGRAM FOR THE LAYOUTS SERVICES AND VERTICAL SCHEMATICS WITHOUT SIZING IS DONE HERE AND SENT FOR APPROVAL </p>
                
                
                <h4 className="mt-5">DESIGN DEVELOPMENT</h4><hr/>
                    <p>DETAILED DESIGN CALCULATIONS AS PER THE PARAMETERS SET IN THE DBR, CLIENT'S DESIGN GUIDELINES IF ANY.  DOING THE NECESSARY  COORDINATION WITH ARCHITECTS AND OTHER CONSULTANTS AS PER NEED.</p>
                    <p> DETAILED LAYOUTS FOR ALL SERVICES WITH ALL DIMENSIONS, SIZES, CAPACITIES OF EQUIPMENTS, TYPICAL CONNECTIONS, MOUNTING AND TERMINATION, DISTRIBUTION DETAILS</p>
                    <p>DETAILED SINGLE LINE SCHEMATIC DIAGRAMS WITH ALL SIZES AND SPECIFICATIONS</p>
                    <p>ETAILED PLANT ROOM AND EQUIPMENT LAYOUTS WITH DIMENSIONS AND EXPECTED SIZES OF EQUIPMENTS WITH ASSOCIATED OPERATING LOADS FOR STRUCTURAL CONSULTANT DESIGN.</p>
                    <p>PREPARATION OF ALL RELEVANT DATA SHEETS FOR EQUIPMENTS.</p>
                    <p>IN CASE  ANY THIRD PARTY CONSULTANT IS NOT APPOINTED AT THIS</p>
                    <p>STAGE, THEN THE LAYOUT SHALL BE DONE FOR THE SERVICES PART WITH NECESSARY ASSUMPTIONS WHATEVER BEST WE KNOW FROM OUR PAST EXPERIENCE FOR THE TENDERING PURPOSE.</p>
                    <p>APPROVAL OF ABOVE SAID DRAWINGS BY THE CLIENT FOR GETTING INTO THE NEXT STAGE.</p>
                
                
                

                <h4 className="mt-5">TENDER DOCUMENTATION & AWARD OF CONTRACTS</h4><hr/>
                    <p>  PREPARATION OF TENDER SPECIFICATIONS  IN CONJUNCTION WITH THE DETAILED DESIGN DRAWINGS</p>
                    <p>  BILL OF MATERIALS</p>
                    <p>GENERAL CONDITIONS OF CONTRACT (THIS IS GENERALLY A CLIENT SCOPE HOWEVER WE CAN ASSIST TO PROVIDE THE SAME)</p>
                    <p>ESTIMATES FOR THE GIVEN BILL OF MATERIALS BASED ON RATES OBTAINED FROM OUR RECENT PROJECTS AWARDED.</p>
                    <p>APPROVAL OF TENDER DOCUMENTS, BOQ, GCC BY THE CLIENT.</p>
                    <p> FLOATING OF TENDERS AND RECEIPT OF SAME BY THE CLIENT.</p>
                    <p>MAKING NECESSARY TECHNICAL COMPARISON AND SEND IT TO PROJECT MANAGERS TO FURTHER CARRYING ON THE COMMERCIAL DISCUSSION WITH THE SERVICES CONTRACTORS</p>
                    <p>ATTENDING TENDER NEGOTIATION MEETING AND PROVIDING NECESSARY CLARIFICATIONS AND ASSISTING CLIENT TO FINALISE THE SUCCESSFUL CONTRACTOR FOR THE SERVICES AS REQUIRED FROM THE TECHNICAL POINT OF VIEW OF SERVICES.</p>



                <h4 className="mt-5">CONSTRUCTION STAGE</h4><hr/>
                    <p>BRIEFING THE SERVICES CONTRACTORS DURING THE KICK-OFF MEETINGS TO START COMMENCING THE WORKS AT SITE FROM THE SERVICES PIONT OF VIEW</p>
                    <p>ATTENDING SCHEDULED SITE CO-ORDINATION MEETINGS (AS MUTUALLY AGREED) AND PROVIDING NECESSARY CLARIFICATIONS AS AND WHEN REQUIRED IN THE FORM OF SKETCES ETC.</p>
                    <p>APPROVING SHOP DRAWINGS PRODUCED BY CONTRACTORS.</p>
                    <p>ASSESSING QUALITY OF WORKS BEING EXECUTED AND PROVIDING NECESSARY CORRECTIVE MEASURES IN LINE WITH THE  TENDER SPECIFICATIONS & BOQ.</p>
                    <p>RECORDING CHANGES SUGGESTED AND CIRCULATING IT TO ALL.</p>
                    <p>ASSISTING CLIENTS IN MAJOR EQUIPMENT INSPECTION AT FACTORY AS MUTUALLY AGREED OR THE TEST RESULTS CAN BE APPROVED AS THE CASE APPLICABLE.</p>
                    <p>APPROVING COMMISSIONING PARAMETERS AS RECORDED BY VENDORS WITH PROJECT MANAGERS.</p>



                <h4 className="mt-5">HAND OVER</h4><hr/>
                    <p>APPROVIING AS BUILT DOCUMENTS & DRAWINGS PREPARED BY THE SERVICES CONTRACTORS.</p>
                    <p> APPROVING OPERATION AND MAINTENANCE MANUAL PREPARED BY THE CONTRACTORS.</p>
                    <p>HANDING OVER THE APPROVED AS BUILT DWG AND O&M MANUAL  TO THE CLIENT (BOTH SOFT AND HARD COPY)</p>
                    <p>ASSISTINGTHE CLIENT IN STABILIZING THE SYSTEM ALONG WITH THE SERVICE CONTRACTORS FOR PERFECT FUCTIONING WHEREVER NECESSARY.</p>
                
                
                
                
                
                
                
                
                </div>









                
            </section>


            <Footer/>
        </div>
    )
}

export default Services
