import React from 'react'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'
import { Link } from 'react-router-dom';
const MauliPride = () => {
    
    return (
        
        <div>
            <Header/>
            <section className="page-header">
                    <h3 className="text-uppercase text-center">Mauli Pride</h3>
                    <center><span className="text-center"><Link to="/projects" className="text-dark"><b>Projects </b> </Link> / Mauli Pride</span></center>
            </section>
            <div className="container mt-5 mb-5">
                <div className="m-4 p-3">
                <div className="row mt-3">
                    <div className="col-lg-6">
                        <div className="shadow">
                        <img src="/images/Photos/type1/3.-Mauli-Pride,--(Phase-1)--(Mauli-Sai-Developer).png" data-toggle="modal" data-target="#exampleModal"   className="img-fluid p-4" />

                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                <div class="modal-body">
                                <img src="/images/Photos/type1/3.-Mauli-Pride,--(Phase-1)--(Mauli-Sai-Developer).png" width="100%" className="img-fluid p-4" />
                                </div>
                                </div>
                            </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card shadow p-4">
                        <h3 className="pt-3">Mauli Pride</h3><hr/>
                        <p className="pt-2"><b>Location :</b><br/> Malad, Mumbai,  Maharastra, India</p>
                        <p className="pt-2"><b>Type :</b><br/> 23 floors with 3 no. Buildings</p>
                        <p className="pt-2"><b>Size :</b><br/> 7 Lakhs sq.ft</p>
                        <p className="pt-2"><b>Architect :</b><br/> M/s. Rasik P Hingoo Associate</p>
                        <p className="pt-2"><b>Scope :</b><br/> MEPF</p>
                        </div>
                    </div>
                </div>
               
                </div>
            </div>    
            <Footer/>
        </div>
    )
}

export default MauliPride
