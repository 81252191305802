import React from 'react'
import Footer from './layout/Footer'
import Header from './layout/Header'

const CMD = () => {
    return (
        <div>
            <Header/>
                <section className="page-header">
                    <h3 className="text-center">CMD</h3>
                </section>


                <section className="bg-light p-3">
                    <div className="container mt-5 ">
                        <div className="row p-3">

                            <div className="col-lg-6">
                                <img src="./images/chairman.png" width="80%" className="img-fluid ml-5" />
                                <h3 className="text-center text-uppercase mt-3">Mr Shrikant Devanpelli</h3>
                            </div>  
                            <div className="col-lg-6">
                                <div className="about-content">
                                <h1 className="aboutus-head">ABOUT CHAIRMAN & MANAGING DIRECTOR</h1>
                                <p className="text-grey aboutus-para">Mr Shrikant Devanpelli is The Founding Chairman and Managing Director of M/s. Prashanti MEP Consultant (PC), a Multi Disciplinary Turnkey Design and Management Consulting Firm Head Quartered in Mumbai, India. He has an extensive 17 years of Utility Engineering Consulting Experience in various sectors such Five Star Hotels, Multi-speciality Hospitals, High Rise Buildings, IT parks & Data Centres, commercial, office buildings, Shopping Malls, Educational Institutes, Govt Buildings, industries, warehouse, large Infrastructure Master Plans, Townships, Places of worship etc., He his Mechanical Engineering From India’s One of the Most Prestigious VJTI College.
                                Mr. Shrikant Devanpelli Senior Associate Consultant and served for about 17 years over all. Later, He started “Prashanti MEP Consultant” in Mumbai with a broad vision to provide high quality consolidated Professional Engineering Consulting.</p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </section>

            <Footer/>
        </div>
    )
}

export default CMD
