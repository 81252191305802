import React from 'react'
import Footer from './layout/Footer'
import Header from './layout/Header'
import { SRLWrapper } from "simple-react-lightbox";
const Gallery = () => {
    return (
        <div>
            <Header/>
                <section className="page-header">
                    <h3 className="text-uppercase text-center">Gallery</h3>
                </section>
                <section className="p-5">
                <SRLWrapper>

                    <div className="container">
                        
                        
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="m-2 ">
                                    <div class="geeks shadow shadow">
                                        <a href="./images/gallery/tab/Convection Centre.png">
                                        <img src="./images/gallery/CONVENTION CENTRE.png" alt="CONVENTION CENTRE"  />
                                        </a> 
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="m-2">
                                    <div class="geeks shadow">
                                        <a href="./images/gallery/tab/Govt. Building 2.png">
                                        <img src="./images/gallery/Govt. Building 2.png" alt="Govt. Building"  />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="m-2">
                                    <div class="geeks shadow">
                                        <a href="./images/gallery/tab/Govt. Building.png">
                                        <img src="./images/gallery/Govt. Building.png" alt="Govt. Building"  />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-4">
                                <div className="m-2">
                                    <div class="geeks shadow">
                                    <a href="./images/gallery/tab/Heritage Building.png">
                                    <img src="./images/gallery/HERITAGE BUILDING.png" alt="HERITAGE BUILDING"  />
                                    </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="m-2">
                                <div class="geeks shadow">
                                    <a href="./images/gallery/tab/High Rise Building.png">
                                    <img src="./images/gallery/HIGH RISE BUILDING.png" alt="HIGH RISE BUILDING"  />
                                    </a>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="m-2">
                                <div class="geeks shadow">
                                    <a href="./images/gallery/tab/Hotel.png">
                                    <img src="./images/gallery/HOTEL.png" alt="HOTEL"  />
                                    </a>
                                </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-4">
                                <div className="m-2">
                                    <div class="geeks shadow">
                                        <a href="./images/gallery/tab/Lawn.png">
                                        <img src="./images/gallery/Lawn.png" alt="Lawn"  />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="m-2">
                                    <div class="geeks shadow">
                                        <a href="./images/gallery/tab/Resort.png">
                                        <img src="./images/gallery/Resort.png" alt="Resort"  />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="m-2">
                                <div class="geeks shadow">
                                    <a href="./images/gallery/tab/Township 1.png">
                                    <img src="./images/gallery/Township 1.png" alt="Township"  />
                                    </a>
                                </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-4 m-auto">
                                <div className="m-2">
                                <div class="geeks shadow">
                                    <a href="./images/gallery/tab/Township 2.png">
                                    <img src="./images/gallery/TOWNSHIP 2.png" alt="TOWNSHIP"  />
                                    </a>
                                </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    </SRLWrapper>
                </section>
            <Footer/>
        </div>
    )
}

export default Gallery
