import React from 'react'

const Slider = () => {
    return (
        <div>
            <section className="desktop-slider">
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-ride="carousel" data-interval="3000" data-pause="false">
                        <img src="./images/slider/b1.jpg" className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-interval="3000" data-ride="carousel" data-pause="false">
                        <img src="./images/slider/b2.jpg" className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-interval="3000" data-ride="carousel" data-pause="false">
                        <img src="./images/slider/b3.jpg" className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-interval="3000" data-ride="carousel" data-pause="false">
                        <img src="./images/slider/b4.jpg" className="d-block w-100" alt="..." />
                        </div>
                        {/* <div className="carousel-item">
                        <img src="./images/slider/b5.jpg" className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item">
                        <img src="./images/slider/b6.jpg" className="d-block w-100" alt="..." />
                        </div> */}
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                    </div>
                </section>

                <section className="mobile-slider">
                <div id="carouselExampleControls1" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-ride="carousel" data-interval="3000" data-pause="false">
                        <img src="./images/slider/bm1.jpg" className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-ride="carousel" data-interval="3000" data-pause="false">
                        <img src="./images/slider/bm2.jpg" className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-ride="carousel" data-interval="3000" data-pause="false">
                        <img src="./images/slider/bm3.jpg" className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item" data-ride="carousel" data-interval="3000" data-pause="false">
                        <img src="./images/slider/bm4.jpg" className="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleControls1" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls1" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                    </div>
                </section>
        </div>
    )
}

export default Slider
