import React from 'react'
import Footer from './layout/Footer'
import Header from './layout/Header'
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    let initialTxt = '';
    const [text, setText] = React.useState(initialTxt);
    function sendEmail(e) {


        e.preventDefault();

        emailjs.sendForm('service_230dyjs', 'template_6jvh49r', e.target, 'user_pq0wUreJquzEjXQdAQvpW')
            .then((result) => {
                console.log(result.text);
                e.target.reset();

            }, (error) => {
                console.log(error.text);
            });
        setInterval(() => {
            setText('We have received your enquiry and will respond to you shortly!');
        }, 2000)
    }



    return (
        <div>
            <Header />
            <section className="page-header">
                <h3 className="text-uppercase text-center">Contact</h3>
            </section>
            <section className="mt-5 mb-5">
                <div className="container  contact-desktop">
                    <div className="row">
                        <div className="col-lg-6">
                            <h4 className="p-2 mb-5 text-center underline1">Connect With Us</h4>
                            <center>
                                <img src="/images/Email.png" width="6%" className="img-fluid" /><br /><br />
                                <p className="font-weight-bold text-center ml-1"> info@prashantimep.com </p>
                                <p className="font-weight-bold text-center ml-4">shrikant@prashantimep.com </p><br /><br />
                            </center>
                            <center>
                                <img src="/images/Call.png" width="4%" className="img-fluid" /><br /><br />
                                <p className="font-weight-bold">+91 98190 26352</p>
                            </center>
                        </div>

                        <div className="col-lg-6">
                            <form onSubmit={sendEmail}>
                                <h4 className="p-2 mb-5 text-center underline1">Let's Work Together</h4>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <input type="text" placeholder="Name" className="form-control" name="name" /><br />
                                        </div>
                                        <div className="col-lg-12">
                                            <input type="email" placeholder="Email" className="form-control" name="email" /><br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <textarea className="form-control" name="message" rows="3" placeholder="Message"></textarea><br />
                                        </div>
                                        <div className="col-lg-12">
                                            <center><input type="submit" className="btn btnhover contact-form-btn" value="Submit" /></center>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <p className="text-center text-success mt-3">{text}</p>
                        </div>
                    </div>

                </div>








                <div className="container  contact-mobile">
                    <div className="row">
                        <div className="col-lg-6">
                            <h4 className="p-2 mb-5 text-center underline">Connect With Us</h4>
                            <center>
                                <img src="/images/Email.png" width="6%" className="img-fluid" /><span className="font-weight-bold ml-1"> info@prashantimep.com </span><br /><br />
                                <span className="font-weight-bold ml-4"> &nbsp;&nbsp; shrikant@prashantimep.com </span><br /><br />

                                <img src="/images/Call.png" width="4%" className="img-fluid" /> <span className="font-weight-bold">+91 98190 26352</span>
                            </center>

                        </div>

                        <div className="col-lg-6">
                            <center>
                                <form>
                                    <h4 className="p-2 mb-5 mt-5 underline1">Let's Work Together</h4>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <input type="text" placeholder="Name" className="form-control" name="name" /><br />
                                            </div>
                                            <div className="col-lg-12">
                                                <input type="email" placeholder="Email" className="form-control" name="email" /><br />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <textarea className="form-control" rows="3" placeholder="Message"></textarea><br />
                                            </div>
                                            <div className="col-lg-12">
                                                <input type="submit" className="btn btnhover contact-form-btn" value="Submit" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </center>
                        </div>
                    </div>

                </div>
            </section>
            <hr />
            <section>
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-lg-12">
                            <center>
                                <div className="mb-5">
                                    <h4 className="">Mumbai</h4>
                                    <p>( Head Office )</p>


                                    {/* <iframe className="mt-3 card" src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3772.4845015870355!2d72.82056944312326!3d18.998360556105474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s01%2C%20Ground%20Floor%2C%20Bldg%20No.%2001%2C%20Gomata%20Nagar%2C%20Behind%20Raheja%20Atlantic%20Tower%2C%20Near%20Shri%20Ram%20Mill%2C%20Lower%20Parel%20(W)%2C%20Mumbai%20%E2%80%93%20400%20013!5e0!3m2!1sen!2sin!4v1626088938160!5m2!1sen!2sin" width="100%" height="350" style={{border:0}} allowfullscreen="" loading="lazy"></iframe> */}

                                    <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3772.5444026276577!2d72.82032397520337!3d18.995717982192364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDU5JzQ0LjYiTiA3MsKwNDknMjIuNCJF!5e0!3m2!1sen!2sin!4v1693314795830!5m2!1sen!2sin" width="100%" height="350" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


                                </div>
                            </center>
                        </div>

                        {/* <div className="col-lg-4">
                            <center>
                                <div className="mb-5">
                                    <h4 className="m-2">Bengaluru </h4>
                                    <p>( Branch Office )</p>
                                    <iframe className="mt-3 card" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.5211768238746!2d77.56641941527035!3d13.066121816331323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae180563186ce9%3A0x7a3a63bb6803f6b2!2s5th%20Cross%20Rd%2C%20Bengaluru%2C%20Karnataka%20560097!5e0!3m2!1sen!2sin!4v1626172483456!5m2!1sen!2sin" width="100%" height="350" allowfullscreen="" style={{ border: 0 }} loading="lazy"></iframe>
                                </div>
                            </center>
                        </div>

                        <div className="col-lg-4">
                            <center>
                                <div className="mb-5">
                                    <h4 className="m-2">Hyderabad  </h4>
                                    <p>( Branch Office )</p>
                                    <iframe className="mt-3 card" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.353314348292!2d78.39107851529958!3d17.49063970442173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb918eb6921b7d%3A0x81590ec7359ee666!2sManjeera%20Majestic%20Commercial!5e0!3m2!1sen!2sin!4v1626172737405!5m2!1sen!2sin" width="100%" height="350" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                                </div>
                            </center>
                        </div> */}

                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default Contact
