import React from 'react'
import Footer from './layout/Footer'
import Header from './layout/Header'

const ProjectList = () => {
    return (
        <div>
            <Header/>
                <section className="page-header">
                    <h3 className="text-center">PROJECT LIST</h3>
                </section>



                <section className="py-5 header">
                    <div className="container">
                       


                        <div className="row">
                            <div className="col-md-3">
                            
                                <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a className="nav-link mb-3 p-3 shadow active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                        <span className="font-weight-bold text-uppercase">RESIDENTIAL BUILDING WITH INFRA</span></a>

                                    <a className="nav-link mb-3 p-3 shadow" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                        
                                        <span className="font-weight-bold text-uppercase">RESIDENTIAL TALL / HIGH RISE BUILDING</span></a>

                                    <a className="nav-link mb-3 p-3 shadow" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                    
                                        <span className="font-weight-bold text-uppercase">RESIDENTIAL BUILDINGS (LOW RISE)</span></a>

                                    <a className="nav-link mb-3 p-3 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        
                                        <span className="font-weight-bold text-uppercase">HOTELS & RESORTS</span></a>

                                        <a className="nav-link mb-3 p-3 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings1" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        
                                        <span className="font-weight-bold text-uppercase">HOSPITAL</span></a>

                                        <a className="nav-link mb-3 p-3 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings2" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        
                                        <span className="font-weight-bold text-uppercase">SCHOOL / COLLEGE / INSTITUTIONS</span></a>

                                        <a className="nav-link mb-3 p-3 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings3" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        
                                        <span className="font-weight-bold text-uppercase">BUNGALOW / PENT HOUSES PROJECTS</span></a>

                                        <a className="nav-link mb-3 p-3 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings4" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        
                                        <span className="font-weight-bold text-uppercase">INDUSTRIAL PROJECTS</span></a>

                                        <a className="nav-link mb-3 p-3 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings5" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        
                                        <span className="font-weight-bold text-uppercase">COMMERCIAL OFFICES / RETAIL / MALLS</span></a>

                                        <a className="nav-link mb-3 p-3 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings6" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        
                                        <span className="font-weight-bold text-uppercase">MIXED OCCUPANCY PROJECTS & SPECIAL PROJECTS</span></a>
                                    </div>
                            </div>


                            <div className="col-md-9">
                                
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div className="tab-pane fade shadow rounded bg-white show active p-5" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                        <h4 className="font-italic mb-4">RESIDENTIAL BUILDING WITH INFRA</h4>
                                        <table className="table tables-sm table-responsive">
                                            <thead>
                                                <th style={{width:'10%'}}>SR NO</th>
                                                <th>PROJECT</th>
                                                <th>AREA / DETAILS</th>
                                                <th>LOCATION</th>
                                                <th>SCOPE</th>
                                            </thead>
                                            <tr>
                                                <td> 1</td>
                                                <td>Gaulakshmi Infra Pvt. Ltd</td>
                                                <td>150 nos. Bungalows and Residential buildings, 17 Acres land with 9 lakhs sq.ft.</td>
                                                <td>Boisar , Maharashtra</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Rainbow County.(Anantham Epic Homes)</td>
                                                <td>'15 Lakhs sq.ft. '(40 floors with 6 no. Buildings)</td>
                                                <td>Panvel, Maharashtra</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Mauli Pride (Mauli Sai Developer)</td>
                                                <td>'7 Lakhs sq.ft. '(23 floors with 3 no. Buildings)</td>
                                                <td>Malad , Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>S3 Eco City (Blossom , Daffodil)(Sanghavi S3 Group)</td>
                                                <td>'6 Lakhs sq.ft. (3 nos. 37 storey Residential Towers )</td>
                                                <td>Mira Road, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>BEST old colony</td>
                                                <td>26 nos. Residential buildings with 520 nos. Apartment, 7 Acres land</td>
                                                <td>Goregaon,Mumbai</td>
                                                <td>Plumbing System Audit</td>
                                            </tr>
                                        </table>    
                                    </div>
                                    
                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <h4 className="font-italic mb-4">RESIDENTIAL TALL / HIGH RISE BUILDING</h4>
                                        <table className="table tables-sm table-responsive">
                                            <thead>
                                                <th style={{width:'10%'}}>SR NO</th>
                                                <th>PROJECT</th>
                                                <th>AREA / DETAILS</th>
                                                <th>LOCATION</th>
                                                <th>SCOPE</th>
                                            </thead>
                                            <tr>
                                                <td>1</td>
                                                <td>Vardhman Flora(Vardhman Group)</td>
                                                <td>55 storey Residential Tower (200m Tall)</td>
                                                <td>Byculla, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Urban Waves(Hare Krushna Developers)</td>
                                                <td>3 nos. 45 storey Residential Towers (150m Tall)</td>
                                                <td>Bandra, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                        </table>    

                                    </div>
                                    
                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <h4 className="font-italic mb-4">RESIDENTIAL BUILDINGS (LOW RISE)</h4>
                                        <table className="table tables-sm table-responsive">
                                            <thead>
                                                 <th style={{width:'10%'}}>SR NO</th>
                                                <th>PROJECT</th>
                                                <th>AREA / DETAILS</th>
                                                <th>LOCATION</th>
                                                <th>SCOPE</th>
                                            </thead>
                                            <tr>
                                                <td>1</td>
                                                <td>East Infinity Residential project,  (Kukreja Infrastructures)</td>
                                                <td>5 Lakhs sq.ft built up area, B+G+2P+20.</td>
                                                <td>Nagpur, Maharashtra</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>DGS Group, Nensey Colony project</td>
                                                <td>5 Lakhs sq.ft. built of area, Residential Project, G+22 with 4 Wings</td>
                                                <td>Borivali, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Solaris 1, at Raheja district 1.Raheja Universal.</td>
                                                <td>4 Lakhs sq.ft. built of area, Residential Project, G+30 with 2 Buildings.</td>
                                                <td>Navi Mumbai, Maharashtra</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Khandelwal Residential.</td>
                                                <td>7 Lakhs sq.ft. built of area, Residential Project, B+G+35</td>
                                                <td>Andheri, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Mauli Omkar.(Mauli Sai Developer)</td>
                                                <td>2 Lakhs sq.ft.(23 floors with 2 no. Buildings)</td>
                                                <td>Malad, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>DGS Group, project </td>
                                                <td>50,000 sq.ft built up area, G+13 storeys </td>
                                                <td>Khar, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Tej Kiran Housing Society</td>
                                                <td>50,000 sq.ft built up area, B+G+10 storeys</td>
                                                <td>Vile Parle, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>Sagar Park, Residential Building </td>
                                                <td>80,000 sq.ft built up area, B+G+13</td>
                                                <td>Ghatkopar,Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>Daffodil, Residential Building(Shree Krishna Construction)</td>
                                                <td>1.2 Lakh sq.ft built up area, (2 buildings, 22 storey)</td>
                                                <td>Bhandup,Mumbai</td>
                                                <td>PF</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>The Hamlet Residential project(Ceear Realty)</td>
                                                <td>70,000 sq.ft built up area, G+9 storeys</td>
                                                <td>Bengaluru</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>The Big Tree Residential project(Ceear Realty)</td>
                                                <td>70,000 sq.ft built up area, 2B+G+7 storeys</td>
                                                <td>Bengaluru</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>Society Residential project</td>
                                                <td>70,000 sq.ft built up area, G+17 storeys</td>
                                                <td>Ghatkopar, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td>Mala CHS Residential project (Verite Development)</td>
                                                <td>50,000 sq.ft built up area, G+15 storeys</td>
                                                <td>Santacruze, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td>Matru Mandir Residential project, Mumbai.(Ratnaraj Group)</td>
                                                <td>60,000 sq.ft built up area, G+22 storeys</td>
                                                <td>Sion, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                                <td>Prabhu Darshan Residential project(The Vibrant Group)</td>
                                                <td>50,000 sq.ft built up area, G+22 storeys</td>
                                                <td>Dadar, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                        </table>    


                                    </div>
                                    
                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <h4 className="font-italic mb-4">HOTELS & RESORTS</h4>
                                        <table className="table tables-sm table-responsive">
                                            <thead>
                                                 <th style={{width:'10%'}}>SR NO</th>
                                                <th>PROJECT</th>
                                                <th>AREA / DETAILS</th>
                                                <th>LOCATION</th>
                                                <th>SCOPE</th>
                                            </thead>
                                            <tr>
                                                <td>1</td>
                                                <td>Motiwala Hotel at, </td>
                                                <td>70,000 sq.ft built up area, 60 no. rooms.</td>
                                                <td>Solapur, Maharashtra.</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Sai Sakshi Hotel </td>
                                                <td>80,000 sq.ft built up area, 60 no. rooms.</td>
                                                <td>Bhandup, Maharashtra</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Fern Forest Bath Resort, (phase 1+2) Project, </td>
                                                <td>1,50,000 sq.ft built up area, 100 no. rooms. (phase 1+2)</td>
                                                <td>Badlapur, Maharashtra</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Hotel at Reva (Udit Infra Pvt Ltd)</td>
                                                <td>1 Lakh sq.ft built up area, 50 no. rooms. </td>
                                                <td>Madhya Pradesh</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Hotel Express INN</td>
                                                <td>Kitchen, Restaurant, Dinning Area, etc</td>
                                                <td>Nashik,Maharashtra</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Shree Shyaam Heritage Resort</td>
                                                <td>1 Lakh sq.ft built up area, 60 no. rooms.</td>
                                                <td>Madhya Pradesh</td>
                                                <td>MEPF</td>
                                            </tr>
                                        </table>   
                                    </div>

                                    
                                    
                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-settings1" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <h4 className="font-italic mb-4">HOSPITAL</h4>
                                        <table className="table tables-sm table-responsive">
                                            <thead>
                                                 <th style={{width:'10%'}}>SR NO</th>
                                                <th>PROJECT</th>
                                                <th>AREA / DETAILS</th>
                                                <th>LOCATION</th>
                                                <th>SCOPE</th>
                                            </thead>
                                            <tr>
                                                <td>1</td>
                                                <td>Mhada Covid Hospital</td>
                                                <td>1200 beds(1000 normal beds + 200 ICU)</td>
                                                <td>Sion, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                        </table>      
                                    </div>

                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-settings2" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                        
                                    <h4 className="font-italic mb-4">SCHOOL / COLLEGE / INSTITUTIONS</h4>
                                        <table className="table tables-sm table-responsive">
                                            <thead>
                                                 <th style={{width:'10%'}}>SR NO</th>
                                                <th>PROJECT</th>
                                                <th>AREA / DETAILS</th>
                                                <th>LOCATION</th>
                                                <th>SCOPE</th>
                                            </thead>
                                            <tr>
                                                <td>1</td>
                                                <td>Vidya Vardhak Samati, School </td>
                                                <td>1 lakh sq.ft built up area, G+7 storeys </td>
                                                <td>Airoli, Maharashtra</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Thakur College</td>
                                                <td>60,000 sq.ft built up area, G+9 storeys </td>
                                                <td>Dahisar, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                        </table>       
                                    </div>



                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-settings3" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <h4 className="font-italic mb-4">BUNGALOW / PENT HOUSES PROJECTS</h4>
                                        <table className="table tables-sm table-responsive">
                                            <thead>
                                                 <th style={{width:'10%'}}>SR NO</th>
                                                <th>PROJECT</th>
                                                <th>AREA / DETAILS</th>
                                                <th>LOCATION</th>
                                                <th>SCOPE</th>
                                            </thead>
                                            <tr>
                                                <td>1</td>
                                                <td>Kanha Lodge</td>
                                                <td>4 Acres built up area </td>
                                                <td>Madhya Pradesh</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>IORA Residence, Sarji Apartment</td>
                                                <td>4000 sq.ft with 5 BHK</td>
                                                <td>Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Clover Windermere Villa</td>
                                                <td>3000 sq.ft with G+1</td>
                                                <td>Pune, Maharashtra</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Sanjay Goyal Bungalow</td>
                                                <td>15,000 sq.ft with G+3</td>
                                                <td>Raipur</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Bhagwati Niwas.</td>
                                                <td>20,000 sq.ft with G+3</td>
                                                <td>Nashik, Maharashtra</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Chetan  Tarwani, Bungalow</td>
                                                <td>10,000 sq. ft. area </td>
                                                <td>Raipur</td>
                                                <td>PF</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Adasada Residence</td>
                                                <td>10,000 sq.ft with G+5</td>
                                                <td>Hyderabad.</td>
                                                <td>MEPF</td>
                                            </tr>
                                        </table>       
                                    </div>




                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-settings4" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <h4 className="font-italic mb-4">INDUSTRIAL PROJECTS</h4>
                                    <table className="table tables-sm table-responsive">
                                            <thead>
                                                 <th style={{width:'10%'}}>SR NO</th>
                                                <th>PROJECT</th>
                                                <th>AREA / DETAILS</th>
                                                <th>LOCATION</th>
                                                <th>SCOPE</th>
                                            </thead>
                                            <tr>
                                                <td>1</td>
                                                <td>Amazon Valshind Warehouse</td>
                                                <td>8 Lakhs sq.ft. </td>
                                                <td>Bhiwandi, Maharastra</td>
                                                <td>MEPF</td>
                                            </tr>
                                            {/* <tr>
                                                <td>2</td>
                                                <td>Data Center, Panvel, Maharastra.(ARSHIYA Ltd.)</td>
                                                <td>25 Lakhs sq.ft. (6 nos. with G+4)</td>
                                                <td>M/s. Ketan A. Patil(Navi Mumbai)</td>
                                                <td>MEPF</td>
                                            </tr> */}
                                        </table>      
                                    </div>

                                    
                                    
                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-settings5" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <h4 className="font-italic mb-4">COMMERCIAL OFFICES / RETAIL / MALLS</h4>
                                    <table className="table tables-sm table-responsive">
                                            <thead className="">
                                                 <th style={{width:'10%'}}>SR NO</th>
                                                <th>PROJECT</th>
                                                <th>AREA / DETAILS</th>
                                                <th>LOCATION</th>
                                                <th>SCOPE</th>
                                            </thead>
                                            <tr>
                                                <td>1</td>
                                                <td>Nav Mart Mall</td>
                                                <td>1 lakh sq.ft built up area </td>
                                                <td>Colaba, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>HSBC</td>
                                                <td>30,000 sq. ft. area (B+G+3)</td>
                                                <td>Fort, Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Lab + office (Oil India Ltd)</td>
                                                <td>14,000 sq. ft. area </td>
                                                <td>Guwahati</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Guardians Office</td>
                                                <td>3,000 sq. ft. area </td>
                                                <td>BKC,Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Nav Mart Hall</td>
                                                <td>1,700 sq. ft. area </td>
                                                <td>Colaba,Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Chetan  Tarwani, Office</td>
                                                <td>10,000 sq. ft. area </td>
                                                <td>Raipur</td>
                                                <td>PF</td>
                                            </tr>
                                        </table>    
                                    </div>

                                   
                                   
                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-settings6" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <h4 className="font-italic mb-4">MIXED OCCUPANCY PROJECTS & SPECIAL PROJECTS</h4>
                                    <table className="table tables-sm table-responsive">
                                            <thead>
                                                 <th style={{width:'10%'}}>SR NO</th>
                                                <th>PROJECT</th>
                                                <th>AREA / DETAILS</th>
                                                <th>LOCATION</th>
                                                <th>SCOPE</th>
                                            </thead>
                                            <tr>
                                                <td>1</td>
                                                <td>Facility Center</td>
                                                <td>Convenience Store, Toilets, Etc.</td>
                                                <td>Varanasi</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>MCGM Gymkhana</td>
                                                <td>15,000 sq.ft built up area, G+1 storeys</td>
                                                <td>Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>SBUT, Cluster 1A</td>
                                                <td>1.8 Lakh sq.ft built up area, 2B+G+15 storeys (Guest room + Community Halls)</td>
                                                <td>Bhendi Bazaar,Mumbai</td>
                                                <td>MEPF</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Banquet Hall + Apartment + warehouse</td>
                                                <td>50,000 sq.ft built up area with 2.5 Acres Land  </td>
                                                <td>Ranchi</td>
                                                <td>MEPF</td>
                                            </tr>
                                        </table>       
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>






            <Footer/>
        </div>
    )
}

export default ProjectList
