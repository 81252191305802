import React from 'react'
import Footer from './layout/Footer'
import Header from './layout/Header'
import { Link } from 'react-router-dom';
const Projects = () => {
    return (
        <div>
             <Header/>
                <section className="page-header">
                    <h3 className="text-uppercase text-center">Projects</h3>
                </section>

<div className="container mt-5 mb-5">
        
                <ul className="nav  justify-content-center" role="tablist">
                    <li className="nav-item card black-border project-tab m-1">
                    <a className="nav-link active pl-4 pr-4" data-toggle="tab" href="#home">Residential Building With Infra</a>
                    </li>
                    <li className="nav-item card black-border project-tab m-1">
                    <a className="nav-link pl-4 pr-4" data-toggle="tab" href="#menu1">Residential Tall / High Rise Building</a>
                    </li>
                    <li className="nav-item card black-border project-tab m-1">
                    <a className="nav-link pl-4 pr-4" data-toggle="tab" href="#menu2">RESIDENTIAL BUILDINGS / LOW RISE</a>
                    </li>
                    <li className="nav-item card black-border project-tab m-1">
                    <a className="nav-link pl-4 pr-4" data-toggle="tab" href="#menu3">HOTELS & RESORTS</a>
                    </li>
                    <li className="nav-item card black-border project-tab m-1">
                    <a className="nav-link pl-4 pr-4" data-toggle="tab" href="#menu4">HOSPITAL</a>
                    </li>
                    <li className="nav-item card black-border project-tab m-1">
                    <a className="nav-link pl-4 pr-4" data-toggle="tab" href="#menu5">SCHOOL / COLLEGE / INSTITUTIONS</a>
                    </li>
                    <li className="nav-item card black-border project-tab m-1">
                    <a className="nav-link pl-4 pr-4" data-toggle="tab" href="#menu6">BUNGALOW / PENT HOUSES PROJECTS</a>
                    </li>
                    <li className="nav-item card black-border project-tab m-1">
                    <a className="nav-link pl-4 pr-4" data-toggle="tab" href="#menu7">INDUSTRIAL PROJECTS</a>
                    </li>
                    <li className="nav-item card black-border project-tab m-1">
                    <a className="nav-link pl-4 pr-4" data-toggle="tab" href="#menu8">COMMERCIAL OFFICES / RETAIL / MALL</a>
                    </li>
                    <li className="nav-item card black-border project-tab m-1">
                    <a className="nav-link pl-4 pr-4" data-toggle="tab" href="#menu9">MIXED OCCUPANCY PROJECTS & SPECIAL PROJECTS</a>
                    </li>
                </ul>



                <div className="tab-content">
                    <div id="home" className="container tab-pane active">
                    <h4 className="text-center text-uppercase pt-5 underline1">Residential Building With Infra</h4>
                    <div className="container">
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/Gaulakshmi-Infra-Townships">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type1/1. Gaulakshmi Infra Township.jpg" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Gaulakshmi Infra Township <br/>(Boisar , Maharashtra)	</p>
                                  </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/Rainbow-County">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/type1/2.-Rainbow-County.--(Anantham-Epic-Homes).png" className="img-fluid" />
                                </div>
                                <p className="text-center pt-2 text-uppercase">Rainbow County <br/>(Panvel, Maharashtra)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/Mauli-Pride">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/type1/3.-Mauli-Pride,--(Phase-1)--(Mauli-Sai-Developer).png" className="img-fluid" />
                                </div>
                                <p className="text-center pt-2 text-uppercase">Mauli Pride <br/>(Malad , Mumbai)	</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/S3EcoCity">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                <img src="./images/Photos/type1/4.-S3-Eco-City-(Blossom-,-Daffodil).png" className="img-fluid" />
                                </div>
                                <p className="text-center pt-2 text-uppercase">S3 Eco City (Blossom , Daffodil)(Sanghavi S3 Group)<br/> (Mira Road, Mumbai	</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/BestOldColony">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                <img src="./images/Photos/type1/5.-BEST-old-colony,-Goregaon,-Mumbai.png" className="img-fluid" />
                                </div>
                                <p className="text-center pt-2 text-uppercase">BEST old colony<br/> (Goregaon, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>










                    <div id="menu1" className="container tab-pane fade">
                    <h4 className="text-center text-uppercase pt-5 underline1">Residential Tall / High Rise Building</h4>
                    <div className="container pb-5">
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/VardhmanFlora">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type2/1.0-Vardhman-Flora.png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Vardhman Flora <br/> (Byculla, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/UrbanWaves">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                <img src="./images/Photos/type2/2. Urban Waves.jpg" className="img-fluid" />
                                </div>
                                <p className="text-center pt-2 text-uppercase">Urban Waves <br/> (Bandra, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>






                    <div id="menu2" className="container tab-pane fade">
                    <h4 className="text-center text-uppercase pt-5 underline1">RESIDENTIAL BUILDINGS / LOW RISE</h4>
                    <div className="container">
                        
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/EastInfinityResidential">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type3/1. East Infinity Residential project..jpg" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">East Infinity Residential<br/>(Kukreja Infrastructures) <br/> (Nagpur, Maharashtra)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/NenseyColony">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/type3/2.-Nensey-Colony-project.png" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">Nensey Colony project <br/>(DGS Group) <br/> (Borivali, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/Solaris1">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">Solaris 1, at Raheja district 1. <br/>Raheja Universal.<br/> (Navi Mumbai, Maharashtra)</p>
                                </div>
                                </Link>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/KhandelwalResidential">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type3/4.-Khandelwal-Residential.-at-Oshiwara.png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Khandelwal <br/> Residential.<br/> (Andheri, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/MauliOmkar">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/type3/5.-Mauli-Omkar.----(Mauli-Sai-Developer).png" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">Mauli Omkar.<br/>(Mauli Sai Developer)<br/> (Malad, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/DGSResidential">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/type3/6. DGS Residential.jpg" className="img-fluid" />
                                </div>
                                <p className="text-center pt-2 text-uppercase">DGS Residential<br/> (DGS Group) <br/>(Khar, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/TejKiran">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Tej Kiran <br/> Housing Society <br/>(Vile Parle, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/SagarPark">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">Sagar Park, <br/>Residential Building <br/>(Ghatkopar,Mumbai	)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/Daffodil">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                <img src="./images/Photos/type3/9. Daffodil, Residential Building.jpg" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">Daffodil, Residential Building <br/> (Shree Krishna Construction)<br/> (Bhandup,Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/TheHamletResidential">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type3/10.-The-Hamlet-Residential-project..png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">The Hamlet Residential project.<br/>(Ceear Realty)<br/> (Bengaluru)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/TheBigTree">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/type3/11.0-The-Big-Tree-Residential-project..png" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">The Big Tree Residential <br/>(Ceear Realty) <br/>(Bengaluru)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/SocietyResidential">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">Society Residential <br/> project<br/> (Ghatkopar, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/MalaCHS">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type3/13.0Mala-CHS-Residential-project..png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Mala CHS Residential <br/>(Verite Development)<br/>(Santacruze, Mumbai) </p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/MatruMandir">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">Matru Mandir Residential <br/>(Ratnaraj Group) <br/> (Sion, Mumbai) </p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/PrabhuDarshan">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/type3/prabhu.png" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">Prabhu Darshan Residential<br/>(The Vibrant Group) <br/>(Dadar, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                        </div>

                    </div>
                    </div>







                    <div id="menu3" className="container tab-pane fade">
                    <h4 className="text-center pt-5 text-uppercase underline1">HOTELS & RESORTS</h4>
                    <div className="container pb-5">
                        
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/MotiwalaHotel">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type4/1. Motiwala Hotel.jpg" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Motiwala Hotel<br/>(Solapur, Maharashtra) </p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/SaiSakshiHotel">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">Sai Sakshi Hotel<br/>(Bhandup, Maharashtra)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/FernForestBathResort">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                <img src="./images/Photos/type4/3. Fern Forest Bath Resort, (phase 1+2).jpg" className="img-fluid" />
                                </div>
                                <p className="text-center pt-2 text-uppercase">Fern Forest Bath Resort, (phase 1+2)<br/>(Badlapur, Maharashtra) </p>
                                </div>
                                </Link>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/HotelatReva">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type4/4. Hotel at Reva..jpg" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Hotel at Reva <br/>(Udit Infra Pvt Ltd) <br/>(Madhya Pradesh	)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/HotelExpressINN">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/type4/5.0-Hotel-Express-INN,-Nashik..png" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">Hotel Express INN <br/>(Nashik, Maharashtra)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/ShreeShyaamHeritage">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">Shree Shyaam Heritage <br/> Resort <br/>(Madhya Pradesh)</p>
                                </div>
                                </Link>
                            </div>
                        </div>

                    </div>
                    </div>







                    <div id="menu4" className="container tab-pane fade">
                    <h4 className="text-center pt-5 text-uppercase underline">HOSPITAL</h4>
                    <div className="container">
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/MhadaCovidHospital">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/type5/1.0-Mhada-Covid-Hospital..png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Mhada Covid Hospital <br/>(Sion, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>






                    <div id="menu5" className="container tab-pane fade">
                   
                    <h4 className="text-center pt-5 text-uppercase underline1">SCHOOL / COLLEGE / INSTITUTIONS</h4>
                    <div className="container pb-5">
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/VidyaVardhakSamatiSchool">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Vidya Vardhak Samati, School <br/>(Airoli, Maharashtra)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/ThakurCollege">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                <img src="./images/Photos/type6/2 Thakur College.jpg" className="img-fluid" />
                                    </div>
                                <p className="text-center pt-2 text-uppercase">Thakur College <br/>(Dahisar, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    </div>






                    <div id="menu6" className="container tab-pane fade">
                   
                    <h4 className="text-center pt-5 text-uppercase underline1">BUNGALOW / PENT HOUSES PROJECTS</h4>
                    <div className="container">
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/KanhaLodge">
                                <div className="card m-3 p-3">
                                    
                                    <div class="geeks">
                                    <img src="./images/Photos/type7/1.0-Kanha-Lodge..png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Kanha Lodge <br/> (Thriving Hotels) <br/>(Madhya Pradesh)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/IORAResidence">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                   
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">IORA Residence, <br/> Sarji Apartment<br/>(Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/CloverWindermereVilla">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type7/3.0-Clover-Windermere-Villa..png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Clover Windermere <br/> Villa <br/>(Pune, Maharashtra)</p>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/SanjayGoyalBungalow">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type7/4.0-Sanjay-Goyal-Bungalow..png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Sanjay Goyal Bungalow <br/>(Raipur)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/BhagwatiNiwas">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Bhagwati Niwas <br/>(Nashik, Maharashtra	)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/ChetanTarwaniBungalow">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Chetan Tarwani Bungalow <br/>(Raipur)</p>
                                </div>
                                </Link>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/AdasadaResidence">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type7/7.0-Adasada-Residence,.png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Adasada Residence<br/>(Hyderabad)</p>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    </div>






                    <div id="menu7" className="container tab-pane fade">
                    <h4 className="text-center pt-5 text-uppercase underline1">INDUSTRIAL PROJECTS</h4>
                    <div className="container pb-5">
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/AmazonValshindWarehouse">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type8/1.0-Amazon-Valshind-Warehouse..png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Amazon Valshind Warehouse<br/>(Bhiwandi, Maharastra)</p>
                                </div>
                                </Link>
                            </div>
                            {/* <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/DataCenter">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                </div>
                                <p className="text-center pt-2 text-uppercase">Data Center,(ARSHIYA Ltd.)</p>
                                </div>
                                </Link>
                            </div> */}
                        </div>
                    </div>
                    </div>





                    <div id="menu8" className="container tab-pane fade">
                    <h4 className="text-center pt-5 text-uppercase underline1">COMMERCIAL OFFICES / RETAIL / MALL</h4>
                    <div className="container">
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/NavMartMall">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Nav Mart Mall at Navy Nagar <br/>(Colaba, Mumbai	)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/HSBCBank">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                <img src="/images/Photos/type9/2 HSBC Bank.jpg" className="img-fluid" />
                                </div>
                                    <p className="text-center pt-2 text-uppercase">HSBC Bank (KCT Group)<br/>(Fort, Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/OilIndiaLtd">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/type9/oil-india.jpg" className="img-fluid" />
                                </div>
                                    <p className="text-center pt-2 text-uppercase">Lab + office (Oil India Ltd)<br/>(Guwahati)</p>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/GuardiansOffice">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                </div>
                                    <p className="text-center pt-2 text-uppercase">Guardians Office<br/>(BKC,Mumbai	)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/NavMartHall">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                        <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Nav Mart Hall, Navy Nagar<br/>(Colaba,Mumbai	)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/ChetanTarwaniOffice">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                        <img src="./images/Photos/placeholder.png" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Chetan  Tarwani, Office<br/>(Raipur)</p>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>






                    <div id="menu9" className="container tab-pane fade">
                    <h4 className="text-center pt-5 text-uppercase underline1">MIXED OCCUPANCY PROJECTS & SPECIAL PROJECTS</h4>
                    <div className="container pb-5">
                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/FacilityCenter">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="/images/Photos/type10/1. Facility Center,.jpg" className="img-fluid p-4" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Facility Center<br/>(Varanasi)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/MCGMGymkhana">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/type10/2.0-MCGM-Gymkhana..png" className="img-fluid" />
                                </div>
                                <p className="text-center pt-2 text-uppercase">MCGM Gymkhana<br/>(Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/SBUTCluster">
                                <div className="card m-3 p-3">
                                <div class="geeks">
                                    <img src="./images/Photos/type10/03.-SBUT-Cluster-1A.png" className="img-fluid" />
                                </div>
                                <p className="text-center pt-2 text-uppercase">SBUT, Cluster 1A<br/>(Bhendi Bazaar,Mumbai)</p>
                                </div>
                                </Link>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-4">
                                <Link className="text-dark text-decoration-none" to="/projects/BanquetHall">
                                <div className="card m-3 p-3">
                                    <div class="geeks">
                                    <img src="./images/Photos/type10/Banquet Hall.jpg" className="img-fluid" />
                                    </div>
                                    <p className="text-center pt-2 text-uppercase">Banquet Hall + Apartment + warehouse <br/>(Ranchi)</p>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>


                </div>

  </div>


  
            <Footer/>
        </div>
    )
}

export default Projects
