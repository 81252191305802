import React from 'react'

const Footer = () => {
    return (
        <div>
            <section className="footer bg-dark">
                <div className="container">
                    <div className="row" style={{ justifyContent: "space-between" }}>

                        <div className="col-lg-3 pb-2">
                            <div className="bg-white p-4">
                                <img src="/images/logo.png" width="70%" className="img-fluid" />
                            </div>
                            <p className="text-light pt-3">
                                PRASHANTI MEP CONSULTANT (PC) is one of the well known Multi Disciplinary
                                Turnkey Engineering Consulting organisation. PC offers complete Turnkey
                                solution for full MEPF and Project management consultancy
                            </p>
                        </div>

                        <div className="col-lg-3 pb-2 ">
                            <div className="p-3 bggrey shadow">
                                <h5 className="text-light text-uppercase">Head Office</h5>
                                <p className="footer-para text-light">
                                    <b className="text-uppercase">Mumbai </b><br />
                                    216, Mahalaxmi Industrial Estate,
                                    Premises Co-Operative Society, Dainik Shivneri Marg, Gandhi Nagar, Lower Parel (W)<br /> Mumbai – 400 013.
                                </p>

                                <p className="footer-para text-light"><b>Mon to fri :</b>- 10am to 6pm</p>
                            </div>
                        </div>



                        {/* <div className="col-lg-3 pb-2">
                            <div className="p-1">
                                <h5 className="text-light text-uppercase">Branch Offices</h5>
                                <p className="footer-para">
                                    <b className="text-uppercase">Bengaluru </b><br />
                                    5th Cross, Hanumanna Layout,
                                    Near Lakeshore Garden,<br /> Vidhyaranyapura (P),<br />
                                    Bengaluru - 560097<br />
                                    Mon to fri :- 10am to 6pm
                                </p>
                            </div>
                            <div className="p-1">
                                // <h5 className="text-light">Branch Office</h5>
                                <p className="footer-para">
                                    <b className="text-uppercase">Hyderabad </b><br />
                                    820, Manjeera Majestic Commercial,
                                    KPHB,<br /> JNTU – Hitech City Road,<br />
                                    Hyderabad – 500072.<br />
                                    Mon to fri :- 10am to 6pm
                                </p>

                            </div>
                        </div> */}


                        <div className="col-lg-3 pb-2">
                            <div className="p-2">
                                <h5 className="text-light text-uppercase">Contact Us</h5>
                                <table>
                                    <tr>
                                        <td>
                                            <img src="/images/Email white.png" className="img-fluid email-footer" /><br /><br />
                                        </td>
                                        <td>
                                            <span className="footer-para ml-1">info@prashantimep.com</span><br />
                                            <span className="footer-para ml-1">shrikant@prashantimep.com</span><br />
                                            {/* <span className="footer-para ml-1">shrikant_shree@rediffmail.com</span><br/> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {/* <img src="/images/Call white.png" width="80%" className="img-fluid email-footer mt-2" /><br /><br /> */}
                                            <img src="/images/smartphone.png" width="90%" className="img-fluid email-footer mt-2" /><br /><br />
                                        </td>
                                        <td>
                                            <p className="footer-para ml-1">+91 9819026352</p>
                                            {/* <p className="footer-para ml-1">+91 2247493923</p> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src="/images/phone.png" width="90%" className="img-fluid email-footer mt-2" /><br /><br />
                                        </td>
                                        <td>
                                            {/* <p className="footer-para ml-1">+91 9819026352</p> */}
                                            <p className="footer-para ml-1">+91 2247493923</p>
                                        </td>
                                    </tr>


                                </table>
                            </div>
                            <div className="p-3">
                                <h5 className="text-light text-uppercase">Members</h5>
                                <img width="80%" className="mt-3 footer-members" src="/images/MEMBERS.png" />
                            </div>
                        </div>

                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <center><p className="footer-credit text-center">© 2021 prashanti MEP consultant.  All rights reserved.</p></center>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Footer
