import React from 'react';
import Footer from './layout/Footer';
import Header from './layout/Header';
import Slider from './layout/Slider';
import { Link } from 'react-router-dom';
import { SRLWrapper } from "simple-react-lightbox";
const Home = () => {
    return (
        <div>
            <Header/>
            <Slider/>
            <section className="about-us">
                <h3 className="text-center text-uppercase mb-4 underline">About Us</h3>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-6 col-12">
                            <img src="./images/abt.jpg" width="95%" className="img-fluid about-logo" />
                        </div>
                        <div className="col-lg-6 col-12">
                            {/* <h3 className="about-heading">Prashant Mep Consultant</h3> */}
                            <h6 className="about-para pt-4">
                            We're end to end solutions for Mechanical, Electrical, Plumbing, Fire Fighting,<br/> Fire Detection, IBMS, etc
                            </h6>
                            <p className="center">Prashanti MEP Consultant (PC) is one of the well known Engineering company. PC provides complete MEP Design Services for all type of Buildings, industry and infra projects. PC offers complete one roof solution of MEP services for your esteem projects across India.</p>
                        </div>
                    </div>
                </div>
                
            </section>






            <section className="mission-desktop">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div className="card p-4 m-2 shadow card-bottom"> <br/>
                                <h3 className="text-center text-uppercase underline1 mb-3">&nbsp;&nbsp; Mission &nbsp;&nbsp;</h3>
                                <p className="text-center text-grey">
                                We strive to achieve par excellence in executing <br/> projects for all the customers may it be infrastructure,<br/> Industrial, residential or  commercial projects.<br/>
                                 Our goal is to achieve. To deliver excellence in <br/> service quality, reliability, safety and customer care.<br/> To win trust of
                                  all customers exceeding their  expectations <br/> and achieve 
                                customer satisfaction. To consistently achieve <br/> high quality, integrity and productivity.
                                </p> <br/>
                                </div>  
                            </div>
                            <div className="col-lg-6">
                                <div className="card p-4 m-2 shadow card-bottom">
                                    <br/>
                                <h3 className="text-center text-uppercase underline1 mb-3">&nbsp;&nbsp; Vision &nbsp;&nbsp;</h3>
                                <p className="text-center text-grey">
                                To believe in creation of value to organisations and society for well being of public and staff. To strive towards customer 
                                satisfaction with best quality of service and time bound execution. To ensure that it will bring more repeat orders and enhance company’s growth.
                                 To create a combination of knowledge and expertise to provide best services. To select, train and 
                                create dedicated work force who can own the responsibility and create recognition for the <br/> company and customer.
                                </p> <br/>
                                </div>  
                            </div>
                           
                        </div>
                    </div>
                    <center><Link to="/about-us"><button className="btn btnhover viewmore mt-5">View More</button></Link></center>
                </section>

                <section className="mission-mobile">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div className="card p-4 m-2 shadow"> <br/>
                                <h3 className="text-center text-uppercase underline1">&nbsp;&nbsp; Mission &nbsp;&nbsp;</h3>
                                <p className="text-center text-grey">
                                We strive to achieve par excellence in executing  projects for all the customers may it be infrastructure, Industrial, residential or  commercial projects.
                                 Our goal is to achieve. To deliver excellence in  service quality, reliability, safety and customer care. To win trust of
                                  all customers exceeding their  expectations and achieve 
                                customer satisfaction. To consistently achieve high quality, integrity and productivity.
                                </p> <br/>
                                </div>  
                            </div>
                            <div className="col-lg-6">
                                <div className="card p-4 m-2 shadow">
                                    <br/>
                                <h3 className="text-center text-uppercase underline1">&nbsp;&nbsp; Vision &nbsp;&nbsp;</h3>
                                <p className="text-center text-grey">
                                To believe in creation of value to organisations and society for well being of public and staff. To strive towards customer 
                                satisfaction with best quality of service and time bound execution. To ensure that it will bring more repeat orders and enhance company’s growth.
                                 To create a combination of knowledge and expertise to provide best services. To select, train and 
                                create dedicated work force who can own the responsibility and create recognition for the company and customer.
                                </p> <br/>
                                </div>  
                            </div>
                           
                        </div>
                    </div>
                    <center><Link to="/about-us"><button  className="btn btnhover viewmore mt-5">View More</button></Link></center>
                </section>





            <section className="service bg-light">
                <h3 className="text-center text-uppercase underline mb-5">&nbsp;&nbsp; SERVICES &nbsp;&nbsp;</h3>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <h3>MEP & Building Services</h3>
                                <ul className="pt-2">
                                    <li>HVAC & Mechanical</li>
                                    <li>Electrical</li>
                                    <li>Plumbing</li>
                                    <li>Fire Detection System</li>
                                    <li>Fire Fighting</li>
                                    <li>Low Voltage System/Security System</li>
                                    <li>Integrated Building Management System (IBMS)</li>
                                    <li>BIM Modelling</li>
                                </ul>
                            </div>  
                            <div className="col-lg-6 col-12">
                                <h3>Infrastructure Development MEP Services</h3>
                                <ul className="pt-2">
                                    <li>High Rise Building</li>
                                    <li>Master Planning</li>
                                    <li>Infrastructure Planning</li>
                                    <li>Infrastructure Expansion And Modernisation</li>
                                    <li>Hotels & Resorts</li>
                                    <li>Commercial buildings.</li>
                                    <li>Bungalows.</li>
                                    <li>Industrial</li>
                                    <li>Hospitals.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <center><Link to="/services"><button className="btn btnhover viewmore mt-5">View More</button></Link></center>
            </section>




















            <section className="workflow">
                <h3 className="text-center text-uppercase mb-5 underline">&nbsp;&nbsp;&nbsp; Work Flow &nbsp;&nbsp;&nbsp;</h3>
                <div className="container">
                    <div className="row">
                        <img src="./images/workflow.png" className="img-fluid" />
                    </div>
                </div>
            </section>

            <section className="projects-desktop bg-light">
            <h3 className="text-center text-uppercase mb-5 underline">&nbsp;&nbsp; Projects &nbsp;&nbsp;</h3>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="geeks">
                                <img src="./images/Project1.png" width="70%" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                        <h1 className="project-heading">HOTELS & RESORTS</h1>
                            
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-6">
                        <h1 className="project-heading">RESIDENTIAL HIGH RISE  <br/> BUILDING</h1>
                        </div>
                        <div className="col-lg-6">
                            <div className="geeks">
                                <img src="./images/Photos/type2/2. Urban Waves.jpg" width="70%" className="img-fluid" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="geeks">
                                <img src="./images/Photos/type1/3.-Mauli-Pride,--(Phase-1)--(Mauli-Sai-Developer).png" width="70%" className="img-fluid"  />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h1 className="project-heading">RESIDENTIAL WITH <br/> INFRA</h1>
                        </div>
                    </div>

                </div>
                <center><Link to="/projects"><button className="btn btnhover viewmore mt-5">View More</button></Link></center>
            </section>



            <section className="projects-mobile bg-light">
                <h3 className="text-center text-uppercase mb-4 pt-5 underline">&nbsp;&nbsp; Projects &nbsp;&nbsp;</h3>
                <div className="container">
                    <div className="row mt-2">
                        <div className="col-lg-12">
                            <div className="geeks">
                                <img src="./images/Project2.png" width="70%" className="img-fluid" />
                            </div>
                            <h6 className="project-heading text-center">RESIDENTIAL BUILDING <br/> WITH INFRA</h6>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <div className="geeks">
                                <img src="./images/Project3.png" width="70%" className="img-fluid" />
                            </div>
                            <h6 className="project-heading text-center">HOTELS & RESORTS</h6> 
                        </div>
                    </div>

                   

                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <div className="geeks">
                                <img src="./images/Project1.png" width="70%" className="img-fluid"  />
                            </div>
                            <h5 className="project-heading text-center">COMMERCIALS OFFICES ,<br/> RETAILS , MALLS</h5>
                        </div>
                    </div>

                </div>
                <center><Link to="/projects"><button className="btn btnhover viewmore mt-3 mb-5">View More</button></Link></center>
            </section>


            <section className="gallery">
                <h3 className="text-center text-uppercase mb-4 underline">&nbsp;&nbsp; Gallery &nbsp;&nbsp;</h3>
                <SRLWrapper>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 p-3">
                                <div className="geeks">
                                <a href="./images/gallery/Gallery-1.jpg">
                                    <img src="./images/gallery/Gallery-1.jpg" className="img-fluid"  />
                                </a>
                                </div>
                                <h5 className="text-center text-uppercase pt-3">HSBC Bank</h5>
                        </div>
                        <div className="col-lg-4 p-3">
                            <div className="geeks">
                                <a href="./images/gallery/Gallery-2.jpg">
                                <img src="./images/gallery/Gallery-2.jpg"  />
                                </a>
                            </div>
                            <h5 className="text-center text-uppercase pt-3">Heritage Building</h5>
                        </div>
                        <div className="col-lg-4 p-3">
                            <div className="geeks">
                                <a href="./images/gallery/Gallery-3.jpg">
                                <img src="./images/gallery/Gallery-3.jpg"  />
                                </a>
                            </div>
                            <h5 className="text-center text-uppercase pt-3">Thakur College</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-3">
                                <div className="geeks">
                                    <a href="./images/gallery/Gallery-4.jpg">
                                    <img src="./images/gallery/Gallery-4.jpg"  />
                                    </a>
                                </div>
                                <h5 className="text-center text-uppercase pt-3">Banquet Hall + Apartment + warehouse</h5>
                        </div>
                        <div className="col-lg-4 p-3">
                            <div className="geeks">
                                <a href="./images/gallery/Gallery-5.jpg">   
                                <img src="./images/gallery/Gallery-5.jpg"  />
                                </a>
                            </div>
                            <h5 className="text-center text-uppercase pt-3">Clover Windermere Villa</h5>
                        </div>
                        <div className="col-lg-4 p-3">
                            <div className="geeks">
                                <a href="./images/gallery/Gallery-6.jpg">
                                <img src="./images/gallery/Gallery-6.jpg"  />
                                </a>
                            </div>
                            <h5 className="text-center text-uppercase pt-3">Sanjay Goyal Bungalow</h5>
                        </div>
                    </div>
                </div>
                
                </SRLWrapper>
            </section>


            <section className="gallery bg-light">
                <h3 className="text-center text-uppercase mb-4 underline">&nbsp;&nbsp; CLIENTELE &nbsp;&nbsp;</h3>
                <div className="container">
                   



                    <div id="carouselExampleControls11" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                                <div className="container">
                                <div className="row">
                                <div className="col-lg-3 col-6">
                                        <div className="shadow-sm bg-white p-4 mt-3">
                                        <center><img src="./images/clients/new/architect-hafeez.jpg" width="100%" className="img-fluid" /></center>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="shadow-sm bg-white p-4 mt-3">
                                        <center><img src="./images/clients/client 2.png" width="100%" className="img-fluid" /></center>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="shadow-sm bg-white p-4 mt-3">
                                        <center><img src="./images/clients/client 3.png" width="100%" className="img-fluid" /></center>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="shadow-sm bg-white p-4 mt-3">
                                        <center><img src="./images/clients/client 4.png" width="100%" className="img-fluid" /></center>
                                        </div>
                                    </div>
                            </div>
                            </div>
                        </div>




                        <div className="carousel-item">
                           
                                <div className="row">
                                <div className="col-lg-3 col-6">
                                        <div className="shadow-sm bg-white p-4 mt-3">
                                        <center><img src="./images/clients/client 5.png" width="100%" className="img-fluid" /></center>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="shadow-sm bg-white p-4 mt-3">
                                        <center><img src="./images/clients/client 6.png" width="100%" className="img-fluid" /></center>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="shadow-sm bg-white p-4 mt-3">
                                        <center><img src="./images/clients/client 7.png" width="100%" className="img-fluid" /></center>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="shadow-sm bg-white p-4 mt-3">
                                        <center><img src="./images/clients/client 8.png" width="100%" className="img-fluid" /></center>
                                        </div>
                                    </div>
                                </div>
                        
                        </div>


                        <div className="carousel-item">
                           
                           <div className="row">
                           <div className="col-lg-3 col-6">
                                   <div className="shadow-sm bg-white p-4 mt-3">
                                   <center><img src="./images/clients/client 9.png" width="100%" className="img-fluid" /></center>
                                   </div>
                               </div>
                               <div className="col-lg-3 col-6">
                                   <div className="shadow-sm bg-white p-4 mt-3">
                                   <center><img src="./images/clients/client 10.png" width="100%" className="img-fluid" /></center>
                                   </div>
                               </div>
                               <div className="col-lg-3 col-6">
                                   <div className="shadow-sm bg-white p-4 mt-3">
                                   <center><img src="./images/clients/client 11.png" width="100%" className="img-fluid" /></center>
                                   </div>
                               </div>
                               <div className="col-lg-3 col-6">
                                   <div className="shadow-sm bg-white p-4 mt-3">
                                   <center><img src="./images/clients/client 12.png" width="100%" className="img-fluid" /></center>
                                   </div>
                               </div>
                           </div>
                   
                    </div>

                    </div>
                    <a className="carousel-control-prev top20 clientele-left" href="#carouselExampleControls11" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next top21 clientele-right" href="#carouselExampleControls11" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                    </div>









                </div>
                <center><Link to="/clients"><button className="btn btnhover viewmore mt-5">View All</button></Link></center>
            </section>




            <section className="service1">
                <h3 className="text-center text-uppercase mb-3 underline">&nbsp;&nbsp; Testimonials &nbsp;&nbsp;</h3>
                    <div className="container">
                            <div id="carouselExampleControls2" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                {/* <SRLWrapper> */}
                                    <div className="carousel-item active">
                                        <center>
                                    <a href="./images/testimonial/test4.jpg" target="_new">
                                        <img src="./images/T 1.2.png" className="img-fluid" alt="CONVENTION CENTRE" />
                                    </a></center>
                                    </div>
                                    
                                    <div className="carousel-item">
                                    <center>
                                    <a href="./images/testimonial/test1.jpg" target="_new">
                                    <img src="./images/T 2.2.png" className="img-fluid" alt="CONVENTION CENTRE" />
                                    </a>                 
                                    </center>                   
                                    </div>
                                   

                                    <div className="carousel-item">
                                    <center>
                                    <a href="./images/testimonial/test3.jpg" target="_new">
                                    <img src="./images/T 3.2.png" className="img-fluid" alt="CONVENTION CENTRE" />
                                    </a>
                                    </center>
                                    </div>
                                    {/* </SRLWrapper> */}
                                </div>
                                <a className="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                        </div>
                    </div>
                    <center><Link to="/testimonials"><button className="btn btnhover viewmore mt-5">View More</button></Link></center>
            </section>

        <Footer/>
        </div>
    )
}

export default Home
