import React from 'react'
import Footer from './layout/Footer'
import Header from './layout/Header'

const Careers = () => {
    return (
        <div>
            <Header/>
            <section className="page-header">
                <h3 className="text-center">CAREERS</h3>
            </section>

            <section className="pt-5 pb-5 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h4 className="text-uppercase mt-3">WHAT WE EXPECT?</h4>
                            <p className="">We Respect & Value Your Hard Gained Education.We Commit to Prosper it further to add More Value  <br/> to your 
                            Career and Life with continuous Intelligence Building and Training to attain the Engineering Excellence.</p>
                        </div>
                        <div className="col-lg-6">
                            <h4 className="mt-3 text-uppercase"> We expect Candidates to be</h4>
                           
                            <p className="">Long term Career Goal with our Esteemed Firm.</p>
                            <p className="">Proactive, Self Interested, Hard working Efficiently & Effectively ,Team work Ready, accept Diversity, Honest & Loyal.</p>
                            <p className="">Add Value to the employee career while adding value to the Company (Win Win Situation).</p>
                            <p className="">Bring in Leadership Quality</p>
                            <p className="">Analytic and Problem Solving Capability</p>
                            <p className="">Cultivate Core Domain Expertise</p>
                        </div>
                    </div>
                </div>

                
            </section>

           

            <section className="mt-5 mb-5">
                <div className="p-2">
                    <div className="container">

                        <div className="shadow p-3">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h5 className="job-head">DESIGNATION : DESIGN HOD(HEAD OF DEPT)	</h5> 
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>EXPERIENCE </b>: 15+years (from consulting background)	</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>Expertise In the areas of </b>: HVAC/ Electrical/ Plumbing / Fire Fighting</p>
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>Educational Qualification </b>: B-Tech / M-Tech (Electrical/ Mechanical)	</p>
                                </div>
                            </div>
                        </div>

                        <div className="shadow p-3 mt-5">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h5 className="job-head">DESIGNATION : DESIGN MANAGERS		</h5> 
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>EXPERIENCE </b>: 10+years (from consulting background)		</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>Expertise In the areas of </b>: HVAC/ Electrical/ Plumbing / Fire Fighting</p>
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>Educational Qualification </b>: B-Tech / M-Tech (Electrical/ Mechanical)	</p>
                                </div>
                            </div>
                        </div>


                        <div className="shadow p-3 mt-5">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h5 className="job-head">DESIGNATION : ACAD DESIGNERS</h5> 
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>EXPERIENCE </b>: 3 TO 10 years (AutoCad) (from consulting background)	</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>Expertise In the areas of </b>: HVAC/ Electrical/ Plumbing / Fire Fighting</p>
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>Educational Qualification </b>: MIN 10+Std or Diploma	</p>
                                </div>
                            </div>
                        </div>

                        <div className="shadow p-3 mt-5">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h5 className="job-head">DESIGNATION : BIM DESIGNERS(REVIT)	</h5> 
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>EXPERIENCE </b>: 2 To 5 years (Acad Revit)	</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>Expertise In the areas of </b>: HVAC/ Electrical/ Plumbing / Fire Fighting</p>
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>Educational Qualification </b>: MIN 10+Std or Diploma	</p>
                                </div>
                            </div>
                        </div>


                        <div className="shadow p-3 mt-5">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h5 className="job-head">DESIGNATION : DESIGN HOD (BRANCH MANAGER) FOR PUNE / HYDERABAD / DELHI / KOLKATTA	</h5> 
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>EXPERIENCE </b>: 15+years	</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>Expertise In the areas of </b>: HVAC/ Electrical/ Plumbing / Fire Fighting</p>
                                </div>
                                <div className="col-lg-6">
                                    <p className="text-grey"><b>Educational Qualification </b>: B-Tech / M-Tech (Electrical/ Mechanical)	</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="container mt-5 pt-3 pb-3">
                            <h4 className="text-center mb-5">Post Your Resume To</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                    <center>
                                        <img className="img-fluid" src="./images/Email.png" />
                                        <p className="mt-3">info@prashantimep.com</p>
                                    </center>
                                </div>
                                <div className="col-lg-6">
                                    <center>
                                        <img className="img-fluid" src="./images/Email.png" />
                                        <p className="mt-3">shrikant@prashantimep.com</p>
                                    </center>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default Careers
