import React from 'react'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'
import { Link } from 'react-router-dom';
const S3EcoCity = () => {
    return (
        <div>
            <Header/>
                <section className="page-header">
                        <h3 className="text-uppercase text-center">S3 Eco City (Blossom , Daffodil)(Sanghavi S3 Group)</h3>
                        <center><span className="text-center"><Link to="/projects" className="text-dark"><b>Projects </b> </Link>/ S3 Eco City</span></center>
                </section>
                <div className="container mt-5 mb-5">
                    <div className="m-4 p-3">
                    <div className="row mt-3">
                        <div className="col-lg-6">
                            <div className="shadow">
                            <img src="/images/Photos/type1/4.-S3-Eco-City-(Blossom-,-Daffodil).png" data-toggle="modal" data-target="#exampleModal"  className="img-fluid p-4" />
                            
                            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                <div class="modal-body">
                                <img src="/images/Photos/type1/4.-S3-Eco-City-(Blossom-,-Daffodil).png" width="100%" className="img-fluid p-4" />
                                </div>
                                </div>
                            </div>
                            </div>
                            
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card shadow p-4">
                            <h3 className="pt-3">S3 Eco City (Blossom , Daffodil)(Sanghavi S3 Group)</h3><hr/>
                            <p className="pt-2"><b>Location :</b><br/> Mira Road, Mumbai,  Maharastra, India</p>
                            <p className="pt-2"><b>Type :</b><br/> 3 nos. 37 storey Residential Towers </p>
                            <p className="pt-2"><b>Size :</b><br/> 6 Lakhs sq.ft</p>
                            <p className="pt-2"><b>Architect :</b><br/>M/s. Sky Line Architects</p>
                            <p className="pt-2"><b>Scope :</b><br/> MEPF</p>
                            </div>
                        </div>
                    </div>
                
                    </div>
                </div>    
                <Footer/>
        </div>
    )
}

export default S3EcoCity
