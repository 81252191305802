import React from 'react'
import Footer from './layout/Footer'
import Header from './layout/Header'

const Profile = () => {
    return (
        <div>
            <Header/>
                <section className="page-header">
                    <h3 className="text-center">COMPANY PROFILE</h3>
                </section>
                <section>
                <div className="container mt-5">
                        <div className="row p-3">
                            <div className="col-lg-6">
                                <h4 className="aboutus-head text-uppercase">We're end to end solutions for Mechanical, Electrical, Plumbing,<br/> Fire Fighting, Fire Detection, IBMS, etc</h4>
                                <p className="text-grey aboutus-para">Prashanti MEP Consultant (PC) is one of the well known Engineering company. PC provides complete MEP Design Services for all type of Buildings,
                                     industry and infra projects. PC offers complete one roof solution of MEP services for your esteem projects across India.
                                     <br/><br/>PC caters to all types of industries including all types of building projects such as Commercial, Residential, Five star hotels,
                                     Hospitals and various Mega infrastructure projects such as Business parks, Townships, Warehousing etc.</p>
                            </div>
                            <div className="col-lg-6">
                               <center><img src="./images/logo.png" width="60%" className="img-fluid aboutus-logo pt-4 ml-5" /></center> 
                            </div>  
                        </div>
                    </div>
                </section>


                <section className="mission-desktop">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div className="card p-4 card-bottom m-2 shadow"> <br/>
                                <h3 className="text-center underline1 text-uppercase">&nbsp;&nbsp; Mission &nbsp;&nbsp;</h3>
                                <p className="text-center text-grey">
                                We strive to achieve par excellence in executing <br/> projects for all the customers may it be infrastructure,<br/> Industrial, residential or  commercial projects.<br/>
                                 Our goal is to achieve. To deliver excellence in <br/> service quality, reliability, safety and customer care.<br/> To win trust of
                                  all customers exceeding their  expectations <br/> and achieve 
                                customer satisfaction. To consistently achieve <br/> high quality, integrity and productivity.
                                </p> <br/>
                                </div>  
                            </div>
                            <div className="col-lg-6">
                                <div className="card p-4 card-bottom m-2 shadow">
                                    <br/>
                                <h3 className="text-center underline1 text-uppercase">&nbsp;&nbsp; Vision &nbsp;&nbsp;</h3>
                                <p className="text-center text-grey">
                                To believe in creation of value to organisations and society for well being of public and staff. To strive towards customer 
                                satisfaction with best quality of service and time bound execution. To ensure that it will bring more repeat orders and enhance company’s growth.
                                 To create a combination of knowledge and expertise to provide best services. To select, train and 
                                create dedicated work force who can own the responsibility and create recognition for the <br/> company and customer.
                                </p> <br/>
                                </div>  
                            </div>
                           
                        </div>
                    </div>
                </section>

                <section className="mission-mobile">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div className="card p-4 m-2  card-bottom shadow"> <br/>
                                <h3 className="text-center underline1 text-uppercase mb-4">&nbsp;&nbsp; Mission &nbsp;&nbsp;</h3>
                                <p className="text-center text-grey">
                                We strive to achieve par excellence in executing  projects for all the customers may it be infrastructure, Industrial, residential or  commercial projects.
                                 Our goal is to achieve. To deliver excellence in  service quality, reliability, safety and customer care. To win trust of
                                  all customers exceeding their  expectations and achieve 
                                customer satisfaction. To consistently achieve high quality, integrity and productivity.
                                </p> <br/>
                                </div>  
                            </div>
                            <div className="col-lg-6">
                                <div className="card p-4 m-2 card-bottom shadow">
                                    <br/>
                                <h3 className="text-center underline1 text-uppercase mb-4">&nbsp;&nbsp; Vision &nbsp;&nbsp;</h3>
                                <p className="text-center text-grey">
                                To believe in creation of value to organisations and society for well being of public and staff. To strive towards customer 
                                satisfaction with best quality of service and time bound execution. To ensure that it will bring more repeat orders and enhance company’s growth.
                                 To create a combination of knowledge and expertise to provide best services. To select, train and 
                                create dedicated work force who can own the responsibility and create recognition for the company and customer.
                                </p> <br/>
                                </div>  
                            </div>
                           
                        </div>
                    </div>
                </section>



  
            
                <section className="py-5 header">
                    <div className="container">
                        <header className="text-center mb-1 pb-0 text-white">
                        <h3 className="text-center text-uppercase underline1"> Our Milestone </h3>
                            <p className="font-italic mb-1">Making advantage of Bootstrap 4 components, easily build an awesome tabbed interface.</p>
                            <p className="font-italic">
                                <a className="text-white" href="">
                                    <u></u>
                                </a>
                            </p>
                        </header>


                        <div className="row">
                            <div className="col-md-3">
                            
                                <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a className="nav-link mb-3 p-3 shadow active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                        <span className="font-weight-bold text-uppercase">2020 PC Opened Its First Branch Office</span></a>

                                    <a className="nav-link mb-3 p-3 shadow" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                        
                                        <span className="font-weight-bold text-uppercase">2020 PC has Associated</span></a>

                                    <a className="nav-link mb-3 p-3 shadow" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                    
                                        <span className="font-weight-bold text-uppercase">2021 PC Opened Its Second Branch Office</span></a>

                                    <a className="nav-link mb-3 p-3 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        
                                        <span className="font-weight-bold text-uppercase">2021 PC has Associated</span></a>

                                        <a className="nav-link mb-3 p-3 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings1" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                        
                                        <span className="font-weight-bold text-uppercase">2021 PC has Associated</span></a>
                                    </div>
                            </div>


                            <div className="col-md-9">
                                
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div className="tab-pane fade shadow rounded bg-white show active p-5" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                        <h4 className="font-italic mb-4">2020 PC Opened Its First Branch Office</h4>
                                        <p className="font-italic text-muted mb-2">In Nov 2020 PC Opened its First Branch Office in Bengaluru. PC Bengaluru Branch is providing services to clients like CR Realty and few more client, etc.</p>
                                    </div>
                                    
                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                        <h4 className="font-italic mb-4">2020 PC has Associated</h4>
                                        <p className="font-italic text-muted mb-2">PC has been appointed by M/s. Hare Krushna Developers LLP for Urban Waves projects with about 7 Lakhs SFT with 3 nos High Rise building (150 mt BLDG height)</p>
                                    </div>
                                    
                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                        <h4 className="font-italic mb-4">2021 PC Opened Its Second Branch Office</h4>
                                        <p className="font-italic text-muted mb-2">In Jan 2021 PC Opened its Second Branch Office in Hyderabad. PC Hyderabad Branch is providing services to clients like Manjeera Construction, and few more client, etc.</p>
                                    </div>
                                    
                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                        <h4 className="font-italic mb-4">2021 PC has Associated</h4>
                                        <p className="font-italic text-muted mb-2">PC has been appointed by M/s.  Satec Envir Engineering (l) Pvt. Ltd. for 1200 nos bed Covid Hospital.</p>
                                    </div>

                                    <div className="tab-pane fade shadow rounded bg-white p-5" id="v-pills-settings1" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                        <h4 className="font-italic mb-4">2021 PC has Associated</h4>
                                        <p className="font-italic text-muted mb-2">PC has been appointed by M/s.  Vardhman Developers LTD. for High Rise BLDG (200 mt BLDG height)</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

        





            <Footer/>
        </div>
    )
}

export default Profile
