import React from 'react'
import Footer from './layout/Footer'
import Header from './layout/Header'

const Clients = () => {
    return (
        <div>
            <Header/>
            <section className="page-header">
                <h3 className="text-uppercase text-center">CLIENTELE</h3>
            </section>
            <section className="mt-5 mb-5">
                <div className="container">

                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/new/architect-hafeez.jpg" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">ARCHITECT HAFEEZ CONTRACTOR</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/new/ARK.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">ARK</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/29.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Skyline Architects</p>
                            </div>
                        </div>
                     
                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/new/land-trades.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">land trades</p>
                            </div>
                        </div>
                     
                        
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/5.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">aesthetic design</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/6.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Arunkumar Designers</p>
                            </div>
                        </div>
                        
                    </div>

                    <div className="row mt-5">

                    <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/7.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Studio 30</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/8.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Chanda's Group</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/20.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Municipal Corporation</p>
                            </div>
                        </div>
                        
                    </div>

                    

                    <div className="row mt-5">
                    <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/10.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Consultants Combined</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/11.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Ceear Reality</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/12.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">DGS Group</p>
                            </div>
                        </div>
                    </div>


                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/13.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">GA Design</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/14.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">HKD</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/15.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Horizon Architects</p>
                            </div>
                        </div>
                       
                    </div>



                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/16.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">KCT Group</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/17.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Kukreja Infrastruture</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/18.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Mauli Pride</p>
                            </div>
                        </div>
                        
                    </div>


                    <div className="row mt-5">

                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/19.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Mayuree Consultant Architect</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 25.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Ms Gaulakshmi Infra</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/22.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Oil India Ltd</p>
                            </div>
                        </div>
                        
                    </div>



                    <div className="row mt-5">
                    <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/23.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Patt & Panchal Associates</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/24.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Praveen Awate Architect</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/25.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Ratnaraj</p>
                            </div>
                        </div>
                    </div>




                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/26.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Redkar & Redkar</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/27.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Satec</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/28.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Seedle</p>
                            </div>
                        </div>
                       
                    </div>


                    <div className="row mt-5">
                    <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/3.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Ambiaance Design</p>
                            </div>
                        </div>
                       



                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/30.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Studio Lotus</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/31.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Thakur Trusts</p>
                            </div>
                        </div>
                       
                    </div>


                    <div className="row mt-5">

                     <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/32.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Verite Self Developement</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 1.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">SSA Architects</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 2.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">The Fern</p>
                            </div>
                        </div>
                        
                    </div>


                    <div className="row mt-5">
                    <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 3.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Tricone</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 4.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">L&T Reality</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 5.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Bombay Reality</p>
                            </div>
                        </div>
                    </div>



                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 6.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">APCRDA</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 7.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Island City Center</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 8.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Dheeraj</p>
                            </div>
                        </div>
                        
                    </div>


                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 9.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Sheltrex Smart Phone City</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 10.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Manjeera</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 11.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Matrix</p>
                            </div>
                        </div>
                        
                    </div>



                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 12.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Raheja Universal</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 13.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">HDIL</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 14.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Ahuja Construction</p>
                            </div>
                        </div>
                        
                    </div>



                    <div className="row mt-5">
                    <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 15.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Eskay Resort</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 16.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Express Inn</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 17.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Saifee Burhani</p>
                            </div>
                        </div>
                    </div>





                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 18.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Neelkanth</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 19.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">OAA</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 24.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Om Developers</p>
                            </div>
                        </div>
                        
                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 21.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Amravati</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/2.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Allianz De Architecture</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/client 23.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Manoj Marda</p>
                            </div>
                        </div>
                       
                       
                    </div>


                    <div className="row mt-5">
                    <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/new/TMDS LOGO.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">TMDS</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/new/Triving Hotels.jpg" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Triving Hotels</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/new/Vardhaman Group.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Vardhaman Group</p>
                            </div>
                        </div>
                    </div>


                    <div className="row mt-5">
                    <div className="col-lg-4">
                            <div className="m-1">
                                <img src="./images/clients/4.png" className="img-fluid clients-logo" />
                                <p className="text-center text-uppercase mt-2">Anantham Epic Home</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default Clients
