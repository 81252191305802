import React from 'react'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'
import { Link } from 'react-router-dom';
const HotelExpressINN = () => {
    return (
        <div>
             <Header/>
            <section className="page-header">
                    <h3 className="text-uppercase text-center">Hotel Express INN<br/> Nashik.</h3>
                    <center><span className="text-center"><Link to="/projects" className="text-dark"><b>Projects </b> </Link> / Hotel Express INN</span></center>
            </section>
            <div className="container mt-5 mb-5">
                <div className="m-4 p-3">
                <div className="row mt-3">
                    <div className="col-lg-6">
                        <div className="shadow">
                        <img src="/images/Photos/type4/5.0-Hotel-Express-INN,-Nashik..png" className="img-fluid p-4" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card shadow p-4">
                        <h3 className="pt-3">Hotel Express INN<br/> Nashik.</h3><hr/>
                        <p className="pt-2"><b>Location :</b><br/> Nashik, Maharastra, India</p>
                        <p className="pt-2"><b>Type :</b><br/> Kitchen, Restaurant, Dinning Area, etc</p>
                        <p className="pt-2"><b>Size :</b><br/> -</p>
                        <p className="pt-2"><b>Architect :</b><br/> M/s. Architect Reza  Kabul, Mumbai.</p>
                        <p className="pt-2"><b>Scope :</b><br/> MEPF</p>
                        </div>
                    </div>
                </div>
               
                </div>
            </div>   
            <Footer/> 
        </div>
    )
}

export default HotelExpressINN
